import $ from 'jquery';

export default function homeHero () {
	//
    $(window).scroll((function() {
		var e = $(window).scrollTop()
		, t = .8 * $(window).width() < $(window).height() ? 2.5 : 1;
		$(window).width() > 768 ? (
			$(".home-hero-grid .home-hero-grid__media-1").css({
				transform: "translate(0px, -" + e * (.035 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__media-2").css({
				transform: "translate(0px, -" + e * (.040 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__media-3").css({
				transform: "translate(0px, -" + e * (.030 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__media-4").css({
				transform: "translate(0px, -" + e * (.031 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__patch-1").css({
				transform: "translate(0px, -" + e * (.011 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__patch-2").css({
				transform: "translate(0px, -" + e * (.014 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__patch-3").css({
				transform: "translate(0px, -" + e * (.008 * t) + "%)"
			}),
			$(".home-hero-grid .home-hero-grid__patch-4").css({
				transform: "translate(0px, -" + e * (.010 * t) + "%)"
			})
			) : (null)
	        })
    	);
}
