import $ from 'jquery';

export default function modalHelp(trigger, target, targetClose) {

	$(trigger).click(function() {
	  	console.log('launch modal');
	  	$(target).addClass('modal--show');
	});

	$(targetClose).click(function() {
	  	$(target).removeClass('modal--show');
	});

	$(document).click(function(event) {
		if (!$(event.target).closest('.modal__content, #button-info-difficulty, #button-info-mobility, .modal__close').length) {
			$(target).removeClass("modal--show");
		}
	});
}
